import { Select, SelectProps, styled } from "@mui/material";

type Props = SelectProps & {
  width?: string;
  hasClearButton?: boolean;
  onClear?: any;
};

const PeerReviewSelect = styled(Select)<Props>`
&& {
  border-width: 1px;
  border-color: ${(props) => props.theme.palette.grey[300]};
  margin: 6px;
  padding-left: 6px;
  width: ${(props) => props.width || "unset"};
  }
}}
`;

export default PeerReviewSelect;
