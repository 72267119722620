import React, { useRef, useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { Formik, Form, Field, FormikProps } from "formik";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { Delete, AddCircleOutline } from "@mui/icons-material";
import { CREATE_DRUG_ORDER, UPDATE_DRUG_ORDER } from "graphql/FormularyQueries";
import { ApolloError, useMutation } from "@apollo/client";
import InputField from "components/formFields/InputField";
import { DRUG_ORDER_CONTENT } from "i18n/constants";
import {
  DrugOrderActionType,
  DrugOrderInterface,
  emptyDrugOrder,
} from "interfaces/formulary";
import DrugContext from "contexts/DrugContext";
import DrugOrderContext from "contexts/DrugOrderContext";
import FormRow from "components/base/FormRow";
import SelectField from "components/formFields/SelectField";
import RangeField from "components/formFields/RangeField";
import { SelectOptionsInterface } from "interfaces/common";
import DoseBandField from "components/formFields/DoseBandField";
import CheckboxField from "components/formFields/CheckboxField";
import Input from "components/base/Input";
import ObjectSelectField from "components/formFields/ObjectSelectField";
import { scrollToErrorField, scrollToElementWithName } from "shared/utils";
import * as Yup from "yup";

const { fields } = DRUG_ORDER_CONTENT;
interface Props {
  selectOptions: SelectOptionsInterface;
}

const invalidMessage = "This value is required";
const tooLowMessage = "The minimum must be greater than 0";
const maxLessThanMinMessage = "The maximum must be greater than the minimum";
const duplicateNameMessage = "Drug order name already exists";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(invalidMessage),
  minimumDose: Yup.number().when("doseUnit.isCalculated", {
    is: false,
    then: (minDose: any) =>
      minDose
        .typeError(invalidMessage)
        .required(invalidMessage)
        .moreThan(0, tooLowMessage),
    otherwise: (minDose: any) => minDose.nullable(),
  }),
  maximumDose: Yup.number().when(["doseUnit.isCalculated", "doseIsRange"], {
    is: (isCalc: boolean, isRange: boolean) => !isCalc && isRange,
    then: (maxDose: any) =>
      maxDose
        .typeError(invalidMessage)
        .required(invalidMessage)
        .when("minimumDose", (minDose: number, maxDose: any) =>
          maxDose.moreThan(minDose || 0, maxLessThanMinMessage),
        ),
    otherwise: (maxDose: any) => maxDose.nullable(),
  }),
  doseBasis: Yup.number().when("doseUnit.isCalculated", {
    is: true,
    then: (doseBasis: any) =>
      doseBasis
        .typeError(invalidMessage)
        .required(invalidMessage)
        .moreThan(0, tooLowMessage),
    otherwise: (doseBasis: any) => doseBasis.nullable(),
  }),
  route: Yup.string().required(invalidMessage),
  doseUnit: Yup.object().shape({
    id: Yup.string().required(invalidMessage),
  }),
  type: Yup.string().required(invalidMessage),
});

const DrugOrderModal = ({ selectOptions }: Props) => {
  const drugOrderContext = useContext(DrugOrderContext);
  const drugContext = useContext(DrugContext);
  const [formValues, setFormValues] =
    useState<DrugOrderInterface>(emptyDrugOrder);
  const [readOnly, setReadOnly] = useState(false);
  const [saving, setSaving] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const formRef = useRef<FormikProps<DrugOrderInterface>>(null);

  const isEditing = drugOrderContext.action === DrugOrderActionType.EDIT;
  const resetForm = (): void => {
    formRef?.current?.resetForm();
    setValidateOnChange(false);
  };

  const ifValidThen = (onValid: any) => {
    formRef.current?.validateForm().then(() => {
      if (formRef.current?.isValid) {
        onValid();
      } else {
        setValidateOnChange(true);
        const errors = formRef.current?.errors;
        scrollToErrorField(errors);
      }
    });
  };

  const routeOptions = [
    { label: drugContext.drug.route, value: drugContext.drug.route },
  ];

  const closeModal = () => {
    resetForm();
    drugOrderContext.setModalIsVisible(false);
  };

  // Handle state based on edit or add when modal is shown
  useEffect(() => {
    if (!drugOrderContext.modalIsVisible) return;
    if (!drugOrderContext.drugOrder) return;

    setReadOnly(drugOrderContext.action === DrugOrderActionType.EDIT);
    setFormValues(drugOrderContext.drugOrder);
    resetForm();
    // eslint-disable-next-line
  }, [drugOrderContext.modalIsVisible]);

  // Refresh detection
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
    // eslint-disable-next-line
  }, []);

  const alertUser = (e: BeforeUnloadEvent) => {
    if (formRef?.current?.dirty) {
      e.preventDefault();
      e.returnValue = "aaa";
    }
  };

  const [addDrugOrderMutation] = useMutation(CREATE_DRUG_ORDER, {
    refetchQueries: ["Formulary"],
    onCompleted: () => {
      closeModal();
      setSaving(false);
    },
    onError: (error: ApolloError) => {
      if (
        error.message.includes(
          'duplicate key value violates unique constraint "unique_formulary_drugorder_name"',
        )
      ) {
        formRef?.current?.setFieldError("name", duplicateNameMessage);
        scrollToElementWithName("name");
      } else {
        alert("Something went wrong");
      }

      setSaving(false);
    },
  });

  const [updateDrugOrderMutation] = useMutation(UPDATE_DRUG_ORDER, {
    refetchQueries: ["Formulary"],
    onCompleted: () => {
      closeModal();
      setSaving(false);
    },
    onError: (error: ApolloError) => {
      if (
        error.message.includes(
          'duplicate key value violates unique constraint "unique_formulary_drugorder_name"',
        )
      ) {
        formRef?.current?.setFieldError("name", duplicateNameMessage);
        const errors = formRef.current?.errors;
        scrollToErrorField(errors);
      } else {
        alert("Something went wrong");
      }
      setSaving(false);
    },
  });

  const fixEmptyNumberValues = (values: any) => {
    const concerns = [
      "doseBasis",
      "minimumDose",
      "maximumDose",
      "minimumDuration",
      "maximumDuration",
      "singleDoseLimit",
      "lifetimeDoseLimit",
    ];
    for (const key of concerns) {
      values[key] = values[key] === "" ? null : values[key];
    }

    return values;
  };

  const getNullFields = (isCalc: boolean) => {
    if (isCalc) {
      return {
        maximumDose: null,
        minimumDose: null,
        doseIsRange: false,
      };
    } else {
      return {
        doseBasis: null,
        roundToNearest: null,
      };
    }
  };

  const editDrugOrder = () => {
    if (!drugOrderContext.drugOrder) {
      throw String("Attempting to edit with no drugOrder selected");
    }

    if (saving) return;
    setSaving(true);
    const values = formRef.current?.values;
    if (values?.doseUnit?.isCalculated === undefined) {
      return;
    }
    const nullFields = getNullFields(values.doseUnit.isCalculated);
    const fixedValues = fixEmptyNumberValues(values);
    updateDrugOrderMutation({
      variables: {
        ...fixedValues,
        doseUnitId: values.doseUnit.id,
        ...nullFields,
      },
    });
  };

  const addDrugOrder = () => {
    if (saving) return;
    setSaving(true);
    const values = formRef.current?.values;
    if (values?.doseUnit?.isCalculated === undefined) {
      return;
    }
    const fixedValues = fixEmptyNumberValues(values);
    const nullFields = getNullFields(values.doseUnit.isCalculated);
    addDrugOrderMutation({
      variables: {
        ...fixedValues,
        doseUnitId: formRef?.current?.values.doseUnit.id,
        ...nullFields,
      },
    });
  };

  const cancel = () => {
    if (isEditing && readOnly) {
      closeModal();
      return;
    }

    if (window.confirm("Are you sure you wish to cancel?")) {
      closeModal();
    }
  };

  const addDoseBand = () => {
    const newFormValues = Object.assign({}, formRef?.current?.values);
    newFormValues.doseBands.push({
      minimum: 0,
      maximum: 0,
      bandedDose: 0,
    });
    setFormValues(newFormValues);
  };

  const removeDoseBand = (index: number) => {
    const newFormValues = Object.assign({}, formRef?.current?.values);
    newFormValues.doseBands.splice(index, 1);
    setFormValues(newFormValues);
  };

  const getTitleString = (option: DrugOrderActionType): string => {
    switch (option) {
      case DrugOrderActionType.ADD:
        return "Add Drug Order";
      case DrugOrderActionType.EDIT:
        return "Modify Drug Order";
      case DrugOrderActionType.CLONE:
        const name = drugOrderContext.drugOrder?.name;
        return `Clone drug order ${name.replace("Clone", "")}`;
    }
  };
  return (
    <Dialog
      open={drugOrderContext.modalIsVisible}
      onClose={closeModal}
      scroll="paper"
      data-test-id="drug-order-modal"
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={!isEditing || !readOnly}
    >
      <DialogTitle
        data-cy="drug-order-modal-title"
        style={{ fontWeight: "bold" }}
      >
        <span>{getTitleString(drugOrderContext.action)}</span>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={formValues}
          innerRef={formRef}
          onSubmit={() => {}}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={validateOnChange}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="name"
                    name="name"
                    label={fields.name}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormRow label="Generic Name" name="genericName" labelXs={3}>
                    <Input
                      value={drugContext.drug.genericName}
                      id={"drug-order-generic-name"}
                      data-test-id={"drug-order-generic-name"}
                      variant="outlined"
                      size="medium"
                      disabled={true}
                    />
                  </FormRow>
                </Grid>
                <Grid item xs={12}>
                  <FormRow label="Brand Name" name="brandName" labelXs={3}>
                    <Input
                      value={drugContext.drug.brandName}
                      id={"drug-order-brand-name"}
                      data-test-id={"drug-order-brand-name"}
                      variant="outlined"
                      size="medium"
                      disabled={true}
                    />
                  </FormRow>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={SelectField}
                    id="route"
                    name="route"
                    label={fields.route}
                    options={routeOptions}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={SelectField}
                    id="type"
                    name="type"
                    label={fields.type}
                    options={selectOptions.drugOrderType}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={ObjectSelectField}
                    id="dose-units"
                    name="doseUnit.id"
                    label={fields.units}
                    options={selectOptions.doseUnit}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>

                {formikProps.values.doseUnit.isCalculated && (
                  <>
                    <Grid item xs={12}>
                      <Field
                        component={InputField}
                        id="dose-basis"
                        name="doseBasis"
                        label={fields.doseBasis}
                        type="number"
                        inputProps={{ min: 0 }}
                        isActive={!isEditing}
                        labelXs={3}
                        simpleErrorDisplayLogic
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {formValues.doseBands.map((_band, i) => (
                        <Grid container key={i}>
                          <FormRow
                            labelXs={3}
                            label={i === 0 ? fields.doseBands : ""}
                            name=""
                          >
                            <Grid item xs={12}>
                              <DoseBandField index={i} isActive={!readOnly} />
                            </Grid>
                          </FormRow>
                          <Grid item xs={2} style={{ margin: "auto 0" }}>
                            {!readOnly && (
                              <Button
                                variant="text"
                                startIcon={<Delete />}
                                onClick={() => {
                                  removeDoseBand(i);
                                }}
                              >
                                Remove
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      ))}

                      {!readOnly && (
                        <FormRow
                          labelXs={3}
                          label={
                            formValues.doseBands.length === 0
                              ? fields.doseBands
                              : ""
                          }
                          name=""
                        >
                          <Grid item xs={12}>
                            <Button
                              variant="text"
                              startIcon={<AddCircleOutline />}
                              onClick={addDoseBand}
                            >
                              Add Dose Band
                            </Button>
                          </Grid>
                        </FormRow>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={InputField}
                        id="round-to-nearest"
                        name="roundToNearest"
                        label={fields.roundToNearest}
                        type="number"
                        min={0}
                        isActive={!readOnly}
                        labelXs={3}
                      />
                    </Grid>
                  </>
                )}
                {!formikProps.values.doseUnit.isCalculated && (
                  <Grid item xs={12}>
                    <RangeField
                      id="dose"
                      minField="minimumDose"
                      maxField="maximumDose"
                      isRangeField="doseIsRange"
                      unitOptions={selectOptions.units}
                      label={fields.dose}
                      isActive={!isEditing}
                      labelXs={3}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    component={SelectField}
                    id="solution"
                    name="solution"
                    label={fields.solution}
                    options={selectOptions.solution}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="frequency"
                    name="frequency"
                    label={fields.frequency}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxField}
                    id="prn"
                    name="prn"
                    label={fields.prn}
                    readOnly={readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RangeField
                    id="duration"
                    minField="minimumDuration"
                    maxField="maximumDuration"
                    isRangeField="durationIsRange"
                    unitOptions={selectOptions.durationUnits}
                    unitsField="durationUnits"
                    label={fields.duration}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="administration-instructions"
                    name="administrationInstructions"
                    label={fields.administrationInstructions}
                    isActive={!readOnly}
                    labelXs={3}
                    rows={3}
                    multiline
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="patient-instructions"
                    name="patientInstructions"
                    label={fields.patientInstructions}
                    isActive={!readOnly}
                    labelXs={3}
                    rows={3}
                    multiline
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="single-dose-limit"
                    name="singleDoseLimit"
                    label={fields.singleDoseLimit}
                    type="number"
                    inputProps={{ min: 0 }}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="lifetime-dose-limit"
                    name="lifetimeDoseLimit"
                    label={fields.lifetimeDoseLimit}
                    type="number"
                    inputProps={{ min: 0 }}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions>
        <Button
          variant={"outlined"}
          data-test-id="btn-drug-order-dialog-cancel"
          onClick={cancel}
        >
          Cancel
        </Button>

        {drugOrderContext.action === DrugOrderActionType.ADD && (
          <Button
            disabled={saving}
            data-test-id="btn-drug-order-dialog-add"
            onClick={() => ifValidThen(addDrugOrder)}
          >
            Add
          </Button>
        )}

        {drugOrderContext.action === DrugOrderActionType.CLONE && (
          <Button
            disabled={saving}
            data-test-id="btn-drug-order-dialog-clone"
            onClick={() => ifValidThen(addDrugOrder)}
          >
            Clone Drug Order
          </Button>
        )}

        {!readOnly && isEditing && (
          <Button
            disabled={saving}
            data-test-id="btn-drug-order-dialog-update"
            onClick={() => ifValidThen(editDrugOrder)}
          >
            Update
          </Button>
        )}

        {readOnly && isEditing && (
          <Button
            disabled={saving}
            data-test-id="btn-drug-order-dialog-edit"
            onClick={() => setReadOnly(false)}
          >
            Edit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DrugOrderModal;
